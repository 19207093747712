import React, { useState, useLayoutEffect, useContext, useRef } from 'react'
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image';
import { useInView } from 'react-intersection-observer';
import SEO from '../components/seo';
import { ThemeContext } from '../components/theme-provider';
import FadeIn from '../components/fade-in';
import PortableText from '../components/portable-text';
import Tabs from '../components/tabs';
import FadeGallery from '../components/fade-gallery';
import CultureItem from '../components/culture-item';
import ArrowDown from '../images/svg/arrow-down.svg';

export default function Impact({data}) {
  const theme = useContext(ThemeContext);

  const [showIntro, setShowIntro] = useState(false);
  const [openHistory, setOpenHistory] = useState(null);
  const [activeHistory, setActiveHistory] = useState(null);
  const [activeCulture, setActiveCulture] = useState(null);
  const [showScrollDown, setShowScrollDown] = useState(false);

  const contentRef = useRef(null);

  useLayoutEffect(() => {
    theme.setLogoColorDark(true);
    theme.setMenuColorDark(true);
    theme.setBackgroundColor(null);

    setTimeout(() => {
      setShowScrollDown(true);
    }, 5000);
  }, []);

  function scrollTo(ref) {
    ref.current.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div>
      <SEO
        title="Impact"
        description={data.impact?.introText}
        imageID={(data.impact.introImage) ? data.impact.introImage.asset._id : null}
      />
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          height: 100vh;
          z-index: -1;
        `}
      >
        {data.impact?.introImage && <Image
            {...data.impact.introImage}
            width={1980}
            style={{
              width: '100%',
              height: '100vh',
              objectFit: 'cover',
              opacity: (showIntro) ? 1 : 0,
              transition: 'opacity 0.6s var(--curve)',
            }}
            alt="Intro Image"
            onLoad={() => setShowIntro(1)}
        />}
      </div>
      <div
        className="type--mono"
        css={css`
          position: absolute;
          bottom: var(--margin);
          width: 100%;
          color: var(--white);
          text-align: center;
          cursor: pointer;
          transition: opacity 0.6s var(--curve);

          &:hover {
            opacity: 0.6;
          }
        `}
        onClick={() => {
          window.scrollTo({
            top: window.innerHeight,
            left: 0,
            behavior: 'smooth'
          });
        }}
      >
        <div
          className="type--small-mono"
          css={css`
            margin-bottom: 1.5rem;
            opacity: ${(showScrollDown) ? 1 : 0};
            transition: opacity 0.6s var(--curve);
          `}
        >
          Scroll Down
        </div>
        <ArrowDown />
      </div>
      <div
        css={css`
          background-color: var(--white);
          margin-top: 100vh;
        `}
      >
        <div
          css={css`
            padding: 0 var(--margin);
            padding-top: 15rem;
            margin-bottom: 11rem;

            @media (max-width: 900px) {
              padding-top: 4rem;
              margin-bottom: 6rem;
            }
          `}
        >
          <FadeIn
            className="type--large"
            css={css`
            `}
          >
            {data.impact?.introText}
          </FadeIn>
        </div>  
        <FadeIn>
          <Tabs tabs={data.impact?.tabs} />
        </FadeIn>
        <FadeIn
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            background-color: ${(data.impact?.sectionColor) ? data.impact?.sectionColor.value : '#FFF'};
            gap: var(--gutter);
            padding: 0 var(--margin);
            padding-top: 5rem;
            margin-bottom: -1px;
          `}
        >
          <div
            css={css`
              grid-column: 5 span;

              @media (max-width: 900px) {
                grid-column: span 12;
              }
            `}
          >
            <div
              css={css`
                margin-bottom: 8rem;

                @media (max-width: 900px) {
                  margin-bottom: 4rem;
                }
              `}
              className="type--large"
            >{data.impact.sectionTitle}</div>
            <div className="type--large">
              {data.impact.sectionIntro}
            </div>
          </div>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(6, 1fr);
              gap: var(--gutter);
              grid-column-start: 7;
              grid-column-end: span 6;
              margin-top: 13.5rem;

              @media (max-width: 900px) {
                grid-column-start: 1;
                grid-column: span 12;
                margin-top: 4rem;
              }
            `}
          >
            {data.impact?.sections.length > 0 && data.impact.sections
              .map((section, i) => (
                <CultureItem
                  css={css`
                    grid-column: span 3;
                    opacity: ${(activeCulture !== null && activeCulture !== i) ? 0.3 : 1};
                    transition: opacity 0.6s var(--curve);

                    @media (max-width: 900px) {
                      grid-column: span 6;
                    }
                  `}
                  key={i}
                  culture={section}
                  onMouseEnter={() => setActiveCulture(i)}
                  onMouseLeave={() => setActiveCulture(null)}
                />
              ))
            }
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    impact: sanityImpact(_id: {regex: "/(drafts\\\\.)?impact/"}) {
      introImage {
        ...Image
      }
      introText
      tabs {
        ... on SanityTabTextImage {
          _key
          _type
          tabTitle
          title
          _rawContent
          image {
            ...Image
          }
        }
      }
      sectionColor {
        value
      }
      sectionTitle
      sectionIntro
      sections {
        _key
        title
        _rawText
      }
    }
  }
`
